import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './journalbrandslider.module.scss';
import { Col, Container, Image, Row } from 'react-bootstrap';
import {
  BrandPost,
  ESBrandDetailData,
  ESJournalDetailData,
  ImageGalleryItem,
  Journal,
  JournalDetail
} from '../../../types/elasticsearch';
//import { brandRating} from '../../../utils/utils';
import { changeImgSrc } from '../../../utils/utils';

import { useRouter } from 'next/router';
import { useAppSettings } from '../../../store/appstate-context';
import Link from 'next/link';

type Props = {
  title?: string;
  journals?: Journal[];
  brandPosts?: BrandPost[];
  imageGallery?: ImageGalleryItem[];
  similarArticles?: ESJournalDetailData[];
  similarBrands?: ESBrandDetailData[];
};

export type DirectionType = 'left' | 'right';
export type BackgroundColorType = 'white' | 'off-white';

const RenderJournalsHome = (journals: ESJournalDetailData[] | Journal[]): React.ReactElement[] => {
  const { setElementToScrollOnBack } = useAppSettings();
  useEffect(() => {
    // In order to prevent jumping to the top/bottom of the page,
    // we have to tell the browser not to mess with the scroll restoration
    if (typeof window !== undefined && window?.history?.scrollRestoration !== 'manual')
      window.history.scrollRestoration = 'manual';
  }, []);

  return journals.map((journal, idx) => {
    const headline = journal.journalDetail?.find((el: JournalDetail) => el.mainHeadline);
    const titleToShow = headline?.mainHeadline || '';

    const handleClick = (id: string): boolean => {
      setElementToScrollOnBack(id);
      return true;
    };

    return (
      <Link href={journal.uri} key={idx}>
        <a className='no-anchor-decoration' onClick={() => handleClick(journal.id)} id={journal.id}>
          <div className='p-0 pr-3 col'>
            <Image
              src={changeImgSrc(journal.featuredImage?.mediaItemUrl) || ''}
              className={styles.image}
              alt={
                journal.featuredImage?.altText
                  ? journal.featuredImage?.altText
                  : journal.featuredImage?.title
              }
            />
            <div className={`mt-4 ${styles.journalTitle}`}>{titleToShow}</div>
          </div>
        </a>
      </Link>
    );
  });
};

const renderJournalsSimilar = (
  journals: ESJournalDetailData[] | Journal[]
): React.ReactElement[] => {
  return journals.map((journal, idx) => {
    const headline = journal.journalDetail?.find((el: JournalDetail) => el.mainHeadline);
    const titleToShow = headline?.mainHeadline || '';

    return (
      <a href={journal.uri} key={idx} className='no-anchor-decoration' id={journal.id}>
        <div className='p-0 pr-3 col'>
          <Image
            src={changeImgSrc(journal.featuredImage?.mediaItemUrl) || ''}
            className={styles.image}
            alt={
              journal.featuredImage?.altText
                ? journal.featuredImage?.altText
                : journal.featuredImage?.title
            }
          />
          <div className={`mt-4 ${styles.journalTitle}`}>{titleToShow}</div>
        </div>
      </a>
    );
  });
};

const renderImageGallery = (imageGallery: ImageGalleryItem[]): ReactElement[] => {
  return imageGallery.map((i, id) => {
    if (!i.mediaItemUrl) return <></>;

    return (
      <Col className='p-lg-0 pr-lg-3' key={id}>
        <Image
          src={changeImgSrc(i.mediaItemUrl)}
          className={styles.imageGallery}
          alt={i.altText ? i.altText : i.title}
        />
        <div className={`mt-lg-3 mt-4 ${styles.galleryName}`}>{i.title}</div>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: i.caption }} />
      </Col>
    );
  });
};

const RenderBrands = (brandPosts: BrandPost[]): ReactElement[] => {
  const { setElementToScrollOnBack } = useAppSettings();

  useEffect(() => {
    // In order to prevent jumping to the top/bottom of the page,
    // we have to tell the browser not to mess with the scroll restoration
    if (typeof window !== undefined && window?.history?.scrollRestoration !== 'manual')
      window.history.scrollRestoration = 'manual';
  }, []);

  return brandPosts.map((item, id) => {
    const imgUrl = item.featuredImage?.mediaItemUrl?.length
      ? changeImgSrc(item.featuredImage.mediaItemUrl)
      : '/assets/images/brandNoPicture.jpg';

    const handleClick = (elementId: string): boolean => {
      setElementToScrollOnBack(elementId);
      return true;
    };

    return (
      <a
        href={item.uri}
        key={id}
        className='no-anchor-decoration '
        onClick={() => handleClick(item.id)}
        id={item.id}
      >
        <div className='p-0 pr-3 col'>
          <Image
            src={imgUrl}
            className={styles.image}
            alt={
              item.featuredImage?.altText ? item.featuredImage?.altText : item.featuredImage?.title
            }
          />
          <Row className='mt-4 mb-1'>
            {/*<Col xs={2} className={styles.brandName}>*/}
            {/*  {brandRating(item.brand_detail.overallRating)}*/}
            {/*</Col>*/}
            <Col className={`d-flex justify-content-center ${styles.brandTitle}`}>
              {item.brand_detail.brandName}
            </Col>
          </Row>
        </div>
      </a>
    );
  });
};

const renderSimilarBrands = (
  similarBrands: ESBrandDetailData[],
  locale: string | undefined
): ReactElement[] => {
  return similarBrands.map((item, id) => {
    const imgUrl = item.featuredImage?.mediaItemUrl?.length
      ? changeImgSrc(item.featuredImage.mediaItemUrl)
      : '/assets/images/brandNoPicture.jpg';
    return (
      <div key={id}>
        <a className='no-anchor-decoration' href={`/${locale}${item.uri}`}>
          <Col className='p-0 pr-3'>
            <Image
              src={imgUrl}
              className={styles.similarBrandImage}
              alt={
                item.featuredImage?.altText
                  ? item.featuredImage?.altText
                  : item.featuredImage?.title
              }
            />
            <Row className='mt-3 pt-1 mb-1'>
              {/*<Col xs={2} className={styles.similarBrandName}>*/}
              {/*  {brandRating(item.header.overallRating)}*/}
              {/*</Col>*/}
              <Col className={`d-flex justify-content-center ${styles.similarBrandTitle}`}>
                {item.header.brandName}
              </Col>
            </Row>
          </Col>
        </a>
      </div>
    );
  });
};

const JournalBrandSlider = ({
  title,
  journals,
  brandPosts,
  similarBrands,
  imageGallery,
  similarArticles
}: Props) => {
  const journalBrandSliderRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ init: false, end: false });

  const { locale } = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (journalBrandSliderRef && journalBrandSliderRef.current) {
        if (journalBrandSliderRef.current.clientWidth < journalBrandSliderRef.current.scrollWidth) {
          setPosition({ init: false, end: true });
        }
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleArrowClick = (direction: DirectionType) => (): void => {
    if (journalBrandSliderRef && journalBrandSliderRef.current) {
      const scrollAmount = journalBrandSliderRef.current.clientWidth - 100;
      let endPosition;
      if (direction === 'right') {
        endPosition = journalBrandSliderRef.current.scrollLeft + scrollAmount;
        journalBrandSliderRef.current.scrollTo({ left: endPosition, behavior: 'smooth' });

        if (
          journalBrandSliderRef.current.clientWidth + endPosition >=
          journalBrandSliderRef.current.scrollWidth
        ) {
          return setPosition({ init: true, end: false });
        } else if (!position.init) {
          setPosition({ init: true, end: true });
        }
      } else {
        endPosition = journalBrandSliderRef.current.scrollLeft - scrollAmount;
        journalBrandSliderRef.current.scrollTo({
          left: journalBrandSliderRef.current.scrollLeft - scrollAmount,
          behavior: 'smooth'
        });

        if (endPosition <= 0) {
          return setPosition({ init: false, end: true });
        } else if (!position.end) {
          setPosition({ init: true, end: true });
        }
      }
    }
  };

  const getArrow = (
    direction: DirectionType,
    backgroundColor: BackgroundColorType
  ): React.ReactNode => {
    return (
      <div
        id={`arrow-${direction}`}
        className={`d-none d-sm-flex ${styles['arrow-container']}`}
        style={direction === 'left' ? { left: '0' } : { right: '0' }}
        onClick={handleArrowClick(direction)}
      >
        <div
          className={
            backgroundColor === 'white'
              ? styles['inner-arrow-container']
              : styles.innerArrowColorContainer
          }
        >
          <div
            className={styles.arrow}
            style={{ transform: `rotate(${direction === 'right' ? '180deg' : '0deg'})` }}
          />
        </div>
        <div
          className={styles.gradient}
          style={
            direction === 'left'
              ? {
                  left: '1.5rem',
                  backgroundImage:
                    'linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0))'
                }
              : {
                  right: '1.5rem',
                  backgroundImage:
                    'linear-gradient(to left, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0))'
                }
          }
        />
      </div>
    );
  };

  return (
    <Container fluid>
      {!!title && (
        <Row className='justify-content-center'>
          <h3
            className={`mt-5 mb-4 pb-2 ${
              journals && journals.length ? styles.journalHeader : styles.brandHeader
            }`}
          >
            {title}
          </h3>
        </Row>
      )}
      <div
        ref={journalBrandSliderRef}
        className={`mt-4 mb-6 pl-3 pl-lg-7 row ${styles.journalBrandWrapper}`}
      >
        {position.init &&
          getArrow('left', imageGallery && imageGallery.length ? 'off-white' : 'white')}
        {imageGallery && imageGallery.length && renderImageGallery(imageGallery)}
        {journals && journals.length && RenderJournalsHome(journals)}
        {brandPosts && brandPosts.length && RenderBrands(brandPosts)}
        {similarBrands && similarBrands.length && renderSimilarBrands(similarBrands, locale)}
        {similarArticles && similarArticles.length && renderJournalsSimilar(similarArticles)}
        {position.end &&
          getArrow('right', imageGallery && imageGallery.length ? 'off-white' : 'white')}
      </div>
    </Container>
  );
};

export default JournalBrandSlider;
