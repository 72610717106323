import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

const usePreviewPageExit = (isPreview: boolean) => {
  useEffect(() => {
    if (isPreview) {
      fetch(`${process.env.NEXT_PUBLIC_HOST}/api/exit-preview-no-redirect`).catch((err) =>
        Sentry.captureException(err)
      );
    }
  }, [isPreview]);
};

export default usePreviewPageExit;
