/* eslint-disable */

import React, { ReactElement } from 'react';
import Head from 'next/head';
import {
  BrandPost,
  BreadcrumbType,
  ESJournalDetailData,
  JournalList
} from '../../types/elasticsearch';
import { useRouter } from 'next/router';
import { changeImgSrc } from '../../utils/utils';

const hostname = 'https://fairlyfab.com/de-DE';

// interface Props {
//   journals?: Journal[];
//   brands?: BrandPost[];
//   metaDescription?: string;
//   breadcrumbs?: [];
// }

export const adjustDateFormat = (): string => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();

  let ddString = dd.toString();
  let mmString = mm.toString();

  if (dd < 10) ddString = '0' + dd;
  if (mm < 10) mmString = '0' + mm;

  return yyyy + '-' + mmString + '-' + ddString;
};

export const BreadcrumbsSchema = (breadcrumbs: BreadcrumbType[]) => {
  if (breadcrumbs && breadcrumbs.length)
    return [
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs
          .map((breadcrumb: BreadcrumbType, idx: number) => {
            return {
              '@type': 'ListItem',
              position: idx + 1,
              item: {
                '@id': hostname + breadcrumb.url,
                name: breadcrumb.text
              }
            };
          })
          .filter((_) => _)
      }
    ];

  return [];
};

export const buildBrandSchemaObjects = (brands: BrandPost[]) =>
  brands.map((brand: BrandPost) => ({
    '@context': 'http://schema.org',
    '@type': 'Product',
    '@id': `${hostname}${brand.uri}`,
    name: brand.title,
    brand: {
      '@type': 'Brand',
      '@id': `${hostname}${brand.uri}`,
      name: brand.title,
      url: `${hostname}${brand.uri}`
    },
    image: changeImgSrc(brand?.featuredImage?.mediaItemUrl),
    url: `${hostname}${brand.uri}`,
    // description: brand,
    itemCondition: 'NewCondition',
    offers: {
      '@type': 'Offer',
      availability: 'InStock',
      price: '0',
      priceCurrency: 'EUR',
      priceValidUntil: adjustDateFormat(),
      url: `${hostname}${brand.uri}`
    },
    sku: brand.id
  }));

export const buildJournalSchemaObjects = (journals: JournalList[]) =>
  journals.map((journal: JournalList) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${hostname}${journal?.link}`
      },
      headline: journal?.title,
      image: [changeImgSrc(journal?.featuredImage?.mediaItemUrl ?? '')],
      datePublished: journal?.dateGmt,
      dateModified: journal?.modifiedGmt,
      author: {
        '@type': 'Person',
        name: journal?.author ?? ''
      },
      publisher: {
        '@type': 'Organization',
        name: 'Fairlyfab',
        logo: {
          '@type': 'ImageObject',
          url: 'public/assets/images/fairlyfab-logo-white.png'
        }
      }
    };
  });

const HomepageSchema = (description = '') => {
  const router = useRouter();
  const result: Array<{}> = [];

  if (router.pathname === '/') {
    // TODO 6.4.2021. After Search functionality is done, update "target" url with correct route for search results
    result.push({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: hostname + '/',
      inLanguage: 'de-DE',
      description,
      potentialAction: {
        '@type': 'SearchAction',
        target: hostname + '/search/?q={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    });
  }
  return result;
};

const BrandsSchema = (brands?: BrandPost[]) => {
  if (brands && brands.length > 0) return buildBrandSchemaObjects(brands);
  return [];
};

const JournalsSchema = (journals?: JournalList[]) => {
  if (journals && journals.length > 0) return buildJournalSchemaObjects(journals);
  return [];
};

// const BrandDetailSchema = (data: ESBrandDetailData) => {
//   const router = useRouter();
//   if (data && data.header)
//     return [
//       {
//         '@context': 'http://schema.org',
//         '@type': 'Product',
//         '@id': `${hostname}${router.asPath}`,
//         name: data?.header?.brandName,
//         brand: {
//           '@type': 'Brand',
//           '@id': `${hostname}${router.asPath}`,
//           name: data?.header?.brandName,
//           url: `${hostname}${router.asPath}`
//         },
//         image: changeImgSrc(data?.header?.mainImage?.imageDesktop?.mediaItemUrl),
//         url: `${hostname}${router.asPath}`,
//         // description: brand,
//         itemCondition: 'NewCondition',
//         offers: {
//           '@type': 'Offer',
//           availability: 'InStock',
//           price: '0',
//           priceCurrency: 'EUR',
//           priceValidUntil: adjustDateFormat(),
//           url: `${hostname}${router.asPath}`
//         }
//       }
//     ];
//
//   return [];
// };

const JournalDetailSchema = (data: ESJournalDetailData) => {
  const router = useRouter();
  const editorData = data?.journalDetail?.filter((_) => _.editor)[0];
  const headlineData = data?.journalDetail?.filter((_) => _.mainHeadline)[0];
  if (data)
    return [
      {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `${hostname}${router.asPath}`
        },
        headline: headlineData?.mainHeadline ?? '',
        image: [changeImgSrc(data?.featuredImage?.mediaItemUrl ?? '')],
        datePublished: data?.date,
        dateModified: data?.dateModified,
        author: {
          '@type': 'Person',
          name: editorData?.editor?.name
        },
        publisher: {
          '@type': 'Organization',
          name: 'Fairlyfab',
          logo: {
            '@type': 'ImageObject',
            url: 'public/assets/images/fairlyfab-logo-white.png'
          }
        }
      }
    ];

  return [];
};

const makeJSONLD = (mySchema: any) => ({
  __html: mySchema
});

// @ts-ignore
const SeoSchema = (props): ReactElement => {
  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={makeJSONLD(
          JSON.stringify([
            !props.branddetail && {
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: hostname + '/',
              logo: {
                '@type': 'ImageObject',
                url: 'public/assets/images/fairlyfab-logo-white.png'
              }
            },
            ...HomepageSchema(props.metaDescription),
            ...BrandsSchema(props.brands),
            ...JournalsSchema(props.journals),
            ...JournalDetailSchema(props.journaldetail),
            ...BreadcrumbsSchema(props.breadcrumbs)
          ])
        )}
      />
    </Head>
  );
};

export default SeoSchema;
